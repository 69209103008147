<template>
  <feedback-section :progress-active="progressActive"
                    :button-description="lastUpdatedText"
                    class="order-info-section"
                    @save="save">
    <section class="order-info-section__sections-wrapper">
      <replacement-section />
      <manual-fees-section />
    </section>
  </feedback-section>
</template>

<script>
  import {mapState} from 'vuex';
  import helpers from '@/helpers';
  import ReplacementSection from '@/components/booking_components/parts/ReplacementSection';
  import ManualFeesSection from '@/components/booking_components/parts/ManualFeesSection';
  import FeedbackSection
    from '@/components/assignment_components/one_assignment/interactive_info/pt_feedback/FeedbackSection';

  export default {
    components: {
      'feedback-section': FeedbackSection,
      'replacement-section': ReplacementSection,
      'manual-fees-section': ManualFeesSection
    },
    data() {
      return {
        progressActive: false
      };
    },
    computed: {
      ...mapState('FeedbackStore', {
        lastUpdatedData: ({lastUpdatedData}) => lastUpdatedData?.job_updated_fees_and_order,
        lastUpdatedJobData: ({lastUpdatedData}) => lastUpdatedData?.job_updated
      }),
      lastUpdatedText() {
        return helpers.feedbackInfo.lastUpdatedText({
          context: this,
          lastUpdatedSectionData: this.lastUpdatedData,
          lastUpdatedJobData: this.lastUpdatedJobData,
        });
      }
    },
    methods: {
      save() {
        const jobId = this.$route.params.id;
        const PostingStore = this.$store.state.PostingStore;

        this.progressActive = true;
        this.$store.commit('PostInterpretationStore/setTrackType', 'job_updated_fees_and_order');
        this.$store.dispatch('PostInterpretationStore/editJobPartially', {
          bookingMechanism: PostingStore.ReplacementStore.bookingMechanism,
          actualCreatedAt: this.$store.getters['PostingStore/ReplacementStore/actualCreatedAt'],
          manualBookingFeeApplied: PostingStore.ManualFeesStore.manualFees.booking,
          manualEditingFeeApplied: PostingStore.ManualFeesStore.manualFees.editing,
          manualTravelBookingFeeApplied: PostingStore.ManualFeesStore.manualFees.travelBooking
        })
          .then((jobObj) => {
            this.$store.commit('OneAssignmentStore/setStoreJob', jobObj);
            this.$store.dispatch('FeedbackStore/getLastUpdatedData', {jobId}).then(() => {
              this.progressActive = false;
            });
          })
          .catch(() => {
            this.progressActive = false;
            this.$store.commit('ModalStore/setModal', {
              component: 'error-modal'
            });
          });
      }
    }
  };
</script>

<style scoped>
  .order-info-section__sections-wrapper {
    margin: 0 -15px;
  }
</style>
