<template>
  <feedback-section :progress-active="progressActive"
                    :button-description="lastUpdatedText"
                    class="assignment-type-section"
                    @save="save">
    <section class="assignment-type-section__sections-wrapper">
      <div class="post-form__section">
        <p class="post-form__section-title assignment-type-section__title">{{ $gettext('Change job type') }}</p>
        <sk-note :text="note"
                 class="assignment-type-section__note" />
        <sk-note v-if="isInPersonAssignment"
                 :text="inPersonAssignmentNote"
                 class="assignment-type-section__note" />
        <div class="assignment-type-section__radios-wrapper">
          <sk-radio v-for="type in jobTypes"
                    :key="type.key"
                    :item-value="type.key"
                    :item-name="'actualAssignmentType'"
                    :preselected-value="actualAssignmentType"
                    :item-text="type.text"
                    class="post-form__field assignment-type-section__radio"
                    @radiochanged="setActualAssignmentType" />
        </div>
      </div>
    </section>
  </feedback-section>
</template>

<script>
  import {mapMutations, mapState} from 'vuex';
  import helpers from '@/helpers';
  import FeedbackSection
    from '@/components/assignment_components/one_assignment/interactive_info/pt_feedback/FeedbackSection';

  export default {
    components: {
      'feedback-section': FeedbackSection
    },
    data() {
      return {
        progressActive: false
      };
    },
    computed: {
      ...mapState('PostingStore/AssignmentTypeStore', {
        actualAssignmentType: ({actualAssignmentType}) => actualAssignmentType || '',
        assignmentType: ({assignmentType}) => assignmentType || ''
      }),
      ...mapState('FeedbackStore', {
        lastUpdatedData: ({lastUpdatedData}) => lastUpdatedData?.job_updated_assignment_type,
        lastUpdatedJobData: ({lastUpdatedData}) => lastUpdatedData?.job_updated
      }),
      lastUpdatedText() {
        return helpers.feedbackInfo.lastUpdatedText({
          context: this,
          lastUpdatedSectionData: this.lastUpdatedData,
          lastUpdatedJobData: this.lastUpdatedJobData,
        });
      },
      jobTypesList() {
        return [{
          condition: true,
          key: '',
          text: this.sameAssignmentTypeText
        }, {
          condition: ['in_person', 'video', 'videoroom'].includes(this.assignmentType),
          key: 'phone',
          text: helpers.getJobInfo.jobType('phone', this)
        }, {
          condition: ['in_person', 'phone', 'videoroom'].includes(this.assignmentType),
          key: 'video',
          text: helpers.getJobInfo.jobType('video', this)
        }, {
          condition: ['phone', 'video', 'videoroom'].includes(this.assignmentType),
          key: 'in_person',
          text: helpers.getJobInfo.jobType('in_person', this)
        }];
      },
      sameAssignmentTypeText() {
        switch (this.assignmentType) {
          case 'video':
            return this.$gettext('Keep as video');
          case 'phone':
            return this.$gettext('Keep as phone');
          case 'in_person':
            return this.$gettext('Keep as in person');
          case 'videoroom':
            return this.$gettext('Keep as videoroom');
        }
      },
      jobTypes() {
        return this.jobTypesList.filter(({condition}) => condition);
      },
      isInPersonAssignment() {
        return ['in_person', 'videoroom'].includes(this.assignmentType);
      },
      note() {
        return this.$gettext('This assignment will be invoiced according to the setting chosen here. If the session did not occur in the originally booked way, it will be invoiced using the method provided here');
      },
      inPersonAssignmentNote() {
        return this.$gettext('Note that travel expenses for in-person sessions may still be invoiced for sessions that were conducted on phone or video, if those expenses were unavoidable/non-refundable');
      }
    },
    methods: {
      ...mapMutations('PostingStore/AssignmentTypeStore', [
        'setActualAssignmentType'
      ]),
      save() {
        const jobId = this.$route.params.id;
        const PostingStore = this.$store.state.PostingStore;

        this.progressActive = true;
        this.$store.commit('PostInterpretationStore/setTrackType', 'job_updated_assignment_type');
        this.$store.dispatch('PostInterpretationStore/editJobPartially', {
          actualSessionType: PostingStore.AssignmentTypeStore.actualAssignmentType
        })
          .then((jobObj) => {
            this.$store.commit('OneAssignmentStore/setStoreJob', jobObj);
            this.$store.dispatch('FeedbackStore/getLastUpdatedData', {jobId}).then(() => {
              this.progressActive = false;
            });
          })
          .catch(() => {
            this.progressActive = false;
            this.$store.commit('ModalStore/setModal', {
              component: 'error-modal'
            });
          });
      }
    }
  };
</script>

<style scoped>
  .assignment-type-section__sections-wrapper {
    margin: 0 -15px;
  }

  .assignment-type-section__radios-wrapper {
    display: flex;
    flex-wrap: wrap;
    margin-top: 5px;
  }

  .assignment-type-section__radio {
    width: auto;
  }

  .assignment-type-section__title {
    width: 100%;
  }

  .assignment-type-section__note {
    margin: 0 15px 10px;
  }
</style>
